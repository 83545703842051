<template>
    <div>        
        <div class="text-sm text-gray-500 mb-10">
            Get started with updating your company profile. All fields are required.
        </div>

        <div class="">

            <form @submit.prevent="submit" novalidate class="card border border-blue-200 pt-16 pb-10 lg:px-10 xl:px-16" key="admin">
                
                <form-group leftIcon="business-outline" :value="companyName" disabled :loading="loadingProfile">
                    Company Name
                </form-group>

                <div class="grid grid-cols-2 gap-8">

                    <div class="col-span-1">
                        <form-group
                            leftIcon="briefcase-outline"
                            name="no_of_exec"
                            v-model="form.data.no_of_exec.value"
                            :form="form"
                            :loading="loadingProfile"
                        >
                            Number of Executives
                        </form-group>
                    </div>

                    <div class="col-span-1">
                        <form-group
                            leftIcon="person-outline"
                            name="no_of_staff"
                            v-model="form.data.no_of_staff.value"
                            :form="form"
                            :loading="loadingProfile"
                        >
                            Number of Staff
                        </form-group>
                    </div>

                </div>

                <form-group
                    leftIcon="map-outline"
                    name="company_address"
                    v-model="form.data.company_address.value"
                    :form="form"
                    :loading="loadingProfile"
                >
                    Company Address
                </form-group>

                <form-group
                    leftIcon="globe-outline"
                    name="company_website"
                    v-model="form.data.company_website.value"
                    :form="form"
                    :loading="loadingProfile"
                >
                    Company Website
                </form-group>

                <div class="grid grid-cols-3">

                    <div class="col-span-2">
                        <form-group
                            type="select"
                            :options="daysOfTheMonth"
                            select-display-type="grid"
                            :select-grid-columns="5"
                            leftIcon="calendar-outline"
                            name="salary_day"
                            v-model="form.data.salary_day.value"
                            :form="form"
                            :loading="loadingProfile"
                        >
                            Salary Day
                        </form-group>
                    </div>

                    <!-- <div class="col-span-1">
                        <form-group
                            type="select" :options="loan_types" leftIcon="help-buoy-outline"
                            name="salary_advance_type"
                            v-model="form.data.salary_advance_type.value"
                            :form="form"
                            :loading="loadingProfile"
                        >
                            Current Loan Type
                        </form-group>
                    </div> -->
                    
                </div>

                <div class="text-right">
                    <button type="submit" class="btn btn-blue btn-md" :disabled="form.loading">
                        <template v-if="form.loading">Submitting...</template>
                        <template v-else>Submit Profile</template>
                    </button>
                </div>

            </form>
            
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                company: null,
                loadingProfile: null,
                form: this.$options.basicForm([
                    {name: 'no_of_exec', rules: 'required|number'},
                    {name: 'no_of_staff', rules: 'required|number'},
                    {name: 'company_address', rules: 'required|min:10'},
                    {name: 'company_website', rules: 'required'},
                    {name: 'salary_day', rules: 'required'},
                    // {name: 'salary_advance_type', rules: 'required'},
                ]),
                loan_types: [
                    {
                        title: 'None',
                        value: 'none'
                    },
                    {
                        title: 'Salary Advance',
                        value: 'salary_advance'
                    },
                    {
                        title: 'Car',
                        value: 'car'
                    },
                    {
                        title: 'Housing',
                        value: 'housing'
                    },
                    {
                        title: 'Emergency',
                        value: 'emergency'
                    },
                    {
                        title: 'Health',
                        value: 'health'
                    },
                    {
                        title: 'Education',
                        value: 'education'
                    }
                ],
            }
        },
        computed: {
            companyName() {
                return this.company?.name;
            },
            errorMessage() {
                if (this.form.error.toString().match(/Error: Network Error/i)) {
                    return 'Please check your internet connection';
                }

                const errors = this.form.error?.response?.data?.errors;
                if (errors && Object.keys(errors).length) {
                    return 'Please check the form for incorrect or missing data';
                }

                return null;
            },
            daysOfTheMonth() {
                const days = [];

                for (var i = 0; i < 31; i++) {
                    const day = i+1;
                    const value = day;
                    const title = day.toPosition();
                    days.push({ title, value });
                }

                return days;
            },
        },
        beforeMount() {
            this.loadCompanyProfile();
        },
        methods: {
            mapProfileData() {
                for (let key in this.form.data) {
                    this.form.data[key].value = this.company?.profile?.[key];
                }
            },

            async loadCompanyProfile() {
                this.loadingProfile = true;

                await this.$get({
                    url: `${this.$baseurl}/companies/${this.user.company_id}`,
                    headers: this.headers,
                    success: response => {
                        this.company = response.data.company;
                        this.mapProfileData();
                    },
                    error: () => {

                    }
                });

                this.loadingProfile = false;
            },
            async submit() {
                if (!this.validateForm(this.form)) {
                    return false;
                }

                this.form.loading = true;

                await this.$post({
                    url: `${this.$baseurl}/companies/profile/create`,
                    data: JSON.stringify({
                        ...this.getFormData(),
                        company_id: this.user.company_id
                    }),
                    headers: {
                        ...this.headers,
                        'Content-Type': 'application/json'
                    },
                    success: (response) => {

                        if (this.company) {
                            this.company = response.data.company;
                            this.$store.commit('session/updateUser', {
                                company: response.data.company
                            });
                            this.$store.dispatch('session/saveUser');
                        }

                        this.$emit('success');
                    },
                    error: (error) => {
                        this.form.error = error;
                        this.mapFormErrors(this.form, error?.response?.data?.errors);
                    }
                });

                this.form.loading = false;
            },
        },
    }
</script>